.dropdown-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  margin: 16px 0; /* Space around dropdown */
}

.dropdown-label {
  font-size: 16px;
  margin-bottom: 8px; /* Space between label and dropdown */
  color: #000;
}

.dropdown-container .ant-select-single {
  height: 45px;
}

.dropdown-container .ant-select-single .ant-select-arrow {
  color: rgba(51, 51, 51, 0.5);
}

.ant-select-dropdown {
  border: 1px solid rgba(51, 51, 51, 0.5);
}

.ant-select-dropdown .ant-select-item:hover {
    background-color: #7A7FFF !important;
}

.ant-select-dropdown .ant-select-item-option-selected {
    background-color: #7A7FFF !important;
}

.dropdown-select .ant-select-selector {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 16px;
  padding: 12px 12px; /* Increase vertical padding */
  height: 48px; /* Increase height */
  display: flex;
  align-items: center; /* Ensure text is centered */
  transition: border-color 0.3s, color 0.3s;
}

.dropdown-select .ant-select-selector:focus {
  border-color: #000000;
}

.dropdown-select .ant-select-selector .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.6); /* Placeholder color */
}

.dropdown-select .ant-select-selector .ant-select-selection-item {
  color: rgba(0, 0, 0, 1); /* Selected item color */
}
