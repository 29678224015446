@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #FFFFFF; */
  /* background: #FFFFFF; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(243, 244, 246);
}

.logo {
  justify-content: center;

  @media (min-width: 1000px) {
    height: 25vh;
    margin: 10vw;
  }

  @media (max-width: 1000px) {
    height: 10vh;
    margin: auto;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

footer {
  margin-top: auto;
}

code {
  font-family: Poppins, sans-serif !important;
}

:root {
  --settings-accent-lightness: 38%;
  --window-inner-height: 100vh;
}

@media (prefers-color-scheme: dark) {
  :root {
      --settings-accent-lightness: 70%;
  }
}

.title {
  @apply text-4xl font-bold ;
}

.over {
  @apply tracking-wide font-semibold text-sm uppercase ;
}

input[type="checkbox"], input[type="radio"] {
  @apply dark:bg-gray-800 dark:border-gray-500;
}

* {
  box-sizing: border-box;
}

.field {
  min-width: 200px;
  max-width: 400px;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: 1px solid #7B7FFF;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  padding:0px;
  margin: 10px;
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
}

#special-field {
  width: 30%;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
  padding: 24px 16px 8px 16px;
}

.field.active input + label {
  top: 4px;
  opacity: 1;
  color: #656AFF;
}

.field.locked {
  pointer-events: none;
}

.field input {
  width: 100%;
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #000000;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
  color: #7A808C;
}
.field input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}

.field input + label.error {
  color: #ec392f;
}

.field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}


.field2 {
  /* min-width: 200px;
  max-width: 400px; */
  width: 90%;
  height: 55px;
  border-radius: 10px;
  border: 2px solid #7B7FFF;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  padding:0px;
  margin: 10px auto;
}

#special-field2 {
  width: 10%;
}

.field2:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field2.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}


.field2.locked {
  pointer-events: none;
}

.field2 input {
  width: 100%;
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #000000;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field2 input::-webkit-input-placeholder {
  color: #7A808C;
}
.field2 input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field2 input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field2 input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.field2 input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}

.field2 input + label.error {
  color: #ec392f;
}

.field2 p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}


/* chat settings */
.chat-settings-fields.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  border-radius: 40px !important;
  background-color: rgb(123, 127, 255) !important;
  height: 40px;
  color: white !important;
}
.MuiSwitch-track {
  background-color: rgb(123, 127, 255) !important;
  opacity: 1 !important;
}
.chat-settings-fields.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: white !important;
}
.MuiSwitch-root.MuiSwitch-sizeMedium.chat-settings-fields.css-o4l5bp-MuiSwitch-root {
  width: 75px !important;
  height: 40px !important; 
}
.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.css-13vpke-MuiSlider-root {
  color: rgb(123, 127, 255);
}
.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.css-161ms7l-MuiButtonBase-root-MuiSwitch-switchBase {
  color: white;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.css-1tkmop-MuiFormLabel-root-MuiInputLabel-root {
  margin-right: 15px !important;
}
.chat-settings-sidebar{
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 1000;
  padding: 20px;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) { /* Target devices with a width of 768px or less */
  .chat-settings-sidebar {
    width: 100%;
  }
  .chatbot-box {
    width: 100% !important;
  }
}
.chatbot-box{
  /* max-width: 600px;
  width: 90%; */
  width: 100%;
  margin: 0 auto;
  /* padding: 20px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins, sans-serif !important;
  height: 93vh;
  overflow: hidden;
  background-color: #FFFFFF;
}

/* All the form labels */
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary {
  color: black !important;
}





/* general inquiry form */
.myinp{
  width: 100%;
}

.myinp .MuiInputBase-root.MuiOutlinedInput-root{
  background-color: #FFFFFF !important ;
  border-radius: 15px !important;
}
.custom-select{
  width: 100%;
}

.custom-select.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
  background-color: #FFFFFF !important ;
  border-radius: 15px !important;
}
.custom-select.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: black !important;
}
.dashed-border-box {
  border-radius: 40px ;
  border: 2px dashed black;
  height: 120px;            
  background-color: rgba(123, 127, 255, 0.2);
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__year-select, .react-datepicker__month-select {
  background-color: transparent;
  margin: 0.5rem;
}

.react-datepicker {
  border-radius: 1rem;
  overflow: hidden;
}
