/* chat settings */
.chat-settings-fields.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  border-radius: 40px !important;
  background-color: rgb(123, 127, 255) !important;
  height: 40px;
  color: white !important;
}
.MuiSwitch-track {
  background-color: rgb(123, 127, 255) !important;
  opacity: 1 !important;
}

.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.css-13vpke-MuiSlider-root {
  color: rgb(123, 127, 255);
}
.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.css-161ms7l-MuiButtonBase-root-MuiSwitch-switchBase {
  color: white;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-lohd6h-MuiSvgIcon-root-MuiSelect-icon {
  color: white;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.css-1tkmop-MuiFormLabel-root-MuiInputLabel-root {
  margin-right: 15px !important;
}
.MuiSwitch-root.MuiSwitch-sizeMedium.chat-settings-fields.css-o4l5bp-MuiSwitch-root {
  width: 75px !important;
  height: 40px !important;
}
.MuiSwitch-thumb.css-17jyosd-MuiSwitch-thumb {
  margin-top: 10px !important;
}
.chat-settings-sidebar {
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  /* padding: 60px; */
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Poppins, sans-serif !important;
}
.chatSettingsHeading {
  font-size: 24px;
  font-weight: 500;
  /*margin-bottom: 25px;  Custom margin */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 25px;
}

.chatSettings-labels {
  font-size: 16px;
  font-weight: 500;
  flex: 0 0 32%;
}
.dropdown-div {
  border-radius: 40px;
}
.setting-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.test-speaker {
  border: 1px solid #6869f7;
  box-shadow: 0px 4px 4px rgba(104, 105, 247, 0.4);
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  padding: 10px;
  color: #7b7fff;
  margin-bottom: 15px;
  border-radius: 30px;
}
.textsize-slider {
  display: flex;
  align-items: center;
  width: 150px;
  margin-left: 8px;
  gap: 10px;
}
.save-settings {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px;
  background-color: #ffc64f;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  color: black;
  font-size: 18px;
}
.sample-text {
  font-size: var(--text-size); /* CSS variable for dynamic font size */
  background-color: #e0e4ff;
  padding: 10px 20px;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.bar {
  height: 1px;
  background-color: rgba(204, 206, 255, 0.5);
  width: 100%;
  margin-bottom: 20px;
}
.close {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  /* Target devices with a width of 768px or less */
  .chat-settings-sidebar {
    width: 100%;
  }
  .chatbot-box {
    width: 100% !important;
  }
}
