/*pdps survey page styles  */
.pdp_survey .pdp_nurturLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 1rem 0;
  width: 100%;
}
.pdp_survey.pdp_container {
  margin: auto;
  font-size: 18px;
  padding: 2rem;
  margin-top: 2rem !important;
  width: 500px;
  height: 100vh;
  box-sizing: border-box;
  justify-content: space-between;
}

.pdp_survey .pdp_title {
  font-size: 1.5rem;
  font-weight: 600;
}
.pdp_survey .pdp_Heading {
  padding: 0rem 3rem;
}

.pdp_survey .pdp_Description {
  padding: 1rem 3rem 0 3rem;
}
.pdp_survey .spanText {
  color: orange;
}

.pdp_survey .pdp_textParagraph {
  color: grey;
  font-size: 16px;
}
.pdp_survey .start_button {
  display: inline-flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
  background-color: #6869f7;
  color: white;
  gap: 8px;
}

/*thankyou survey page styles  */
.thankYou_Container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
}

.thankYou_Container .thankyou_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.thankYou_Container .thankYou_description {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  width: 80%;
  max-width: 400px;
  height: auto;
  padding: 50px;
  border-radius: 8px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.thankYou_Container .thankYou_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 20px;
  bottom: 0;
}

.thankYou_Container .thankyou_startButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #6869f7;
  color: white;
  gap: 8px;
}

/*welcome survey page styles  */
.welcomeSurvey {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  width: 100%;
  align-items: center;
  padding: 20px 20px;
  overflow: hidden;
}
.welcomeSurvey .welcome_container {
  width: 100%;
  max-width: 400px;
}
.welcomeSurvey .top_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcomeSurvey .main_heading {
  font-size: 2rem;
  font-weight: bold;
}
.welcomeSurvey h2 {
  text-align: center;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  display: block;
}
.welcomeSurvey .title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.welcomeSurvey .wStep1 {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  font-size: 16px;
  align-items: center;
  width: 100%;
}
.welcomeSurvey .WelcomeSurveyDescription {
  background-color: #e5e6ff;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  width: 100%;
  gap: 10px;
  max-width: 400px;
  height: auto;
  padding: 2rem;
  border: 3px solid #6869f7;
  border-radius: 20px;
  margin: 0px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.survey_LearnButton button {
  background-color: rgb(255, 207, 79);
  border-radius: 20px;
  margin: 10px 0px;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
.survey_LearnButton button:hover {
  background-color: rgb(255, 179, 50);
}

.welcomeSurvey .startSurvey_Button {
  display: inline-flex;
  align-items: center;
  border: none;
  cursor: pointer;
  margin: 30px 0px;
  justify-content: center;
  color: white;
  background-color: #6869f7;
  width: 100%;
}
@media (max-width: 800px) {
  .pdp_survey.pdp_container {
    width: 100%;
  }
  .welcomeSurvey .startSurvey_Button {
    width: 100%;
    font-size: 16px;
  }
  .welcomeSurvey .main_heading {
    font-size: 2rem;
    font-weight: bold;
  }
  .welcomeSurvey .title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .welcomeSurvey .WelcomeSurveyDescription {
    margin-top: 20px;
  }
}
