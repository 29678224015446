/* Base styles for mobile devices (small screens) */
.support-panel-sidebar {
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* Full width on mobile */
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Poppins, sans-serif !important;
}

.supportPanelHeading {
  font-size: 20px; /* Adjust font size for mobile */
  font-weight: 500;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 15px; /* Smaller margin for mobile */
}

.supportPanel-labels {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 5px !important;
}

.dropdown-div {
  border-radius: 40px;
}

.cardStyle {
  max-width: 100%;
  height: 100px;
  margin: 5px;
  box-shadow: 3;
  border-radius: 12px;
  overflow: hidden;
}

.boxStyle {
  display: flex;
  flex-direction: column; /* Ensures items stack vertically */
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding: 1.5rem; /* 1.5rem padding */
}

.headerContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 20px;
  margin-left: -20px;
}

/* Styling for the back button */
.backButton {
  cursor: pointer;
}
.generalInquiryHeading {
  font-size: 20px; /* Adjust font size for mobile */
  font-weight: 500;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.generalInquiryForm {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  font-size: 16px !important;
  padding-top: 10px; /* Add some padding for mobile */
}

.general-inquiry {
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* Full width on mobile */
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Poppins, sans-serif !important;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align items at the top */
}

.formField {
  color: #000;
}
.inputLabel {
  color: #000 !important;
  font-size: 16px !important;
  padding: 1px !important;
}

.inputField {
  border-radius: 20px !important;
  height: 40px !important;
}

.descriptionField .MuiOutlinedInput-root {
  min-height: 100px;
}

.descriptionField .MuiInputBase-input {
  padding: 8px;
}

.selectField {
  border-radius: 20px !important;
  height: 40px !important;
}
.selectField .MuiSelect-select::placeholder {
  color: lightgrey; 
}

.support-panel-sidebar .MuiOutlinedInput-root {
  border-radius: 20px !important;
  height: 40px !important;
}

/* .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff8c00 !important; /* You can change the color to match your design
} */

.MuiSelect-icon {
  display: none !important;
}

.fileUpload {
  background-color: #e3e3fb;
  height: 100px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(116, 113, 113);
  padding: 0 20px;
  gap: 2px;
  width: 100%; /* Make it responsive */
}

.fileUploadLabel {
  display: flex;
  align-items: center;
  gap: 2px;
}

.fileName {
  margin-top: 10px;
  color: #000;
}

.fileUploadText {
  color: #000;
  font-weight: 400;
}

.uploadButton {
  margin-right: 10px;
  color: #000;
}
.uploadButton svg {
  color: #000 !important; /* Target the svg directly to override any default styles */
}

.submitButtonContainer {
  margin-bottom: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  width: 100%; /* Full width for mobile */
}

.submitButton {
  border-radius: 30px !important;
  background-color: #ffcf4f !important;
  text-transform: none !important;
  color: #000 !important;
  width: 100% !important; /* Full width button on mobile */
}

.submitButton:hover {
  background-color: #ff8c00;
}

/* Media Queries for Desktop (Larger Screens) */
@media (min-width: 768px) {
  .support-panel-sidebar {
    width: 350px; /* Set width for larger screens */
  }

  .supportPanelHeading {
    font-size: 24px; /* Larger font size for desktop */
    margin-bottom: 25px; /* Larger margin for desktop */
  }

  .submitButtonContainer {
    width: 200px !important;
  }
}

@media (min-width: 1024px) {
  /* More specific styles for larger screens (desktops/laptops) */
  .support-panel-sidebar {
    width: 350px;
  }

  .generalInquiryForm {
    max-width: 700px !important;
    margin: 0 auto !important;
  }
}
