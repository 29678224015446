html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Poppins, sans-serif !important;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-container {
  flex: 1; /* Takes available space between header and footer */
  overflow-y: auto; /* Enables scrolling for chat content */
}

.chat-footer {
  display: flex;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #e3e3fb;
  background-color: #fff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
}

.input-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.options-btn {
  border: none;
  background-color: #ffcf4f;
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  height: 40px;
  text-align: center;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.user-chat-input {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 16px;
  resize: none;
  white-space: pre-wrap;
}

.submit-btn {
  border-radius: 20px;
  border: none;
  background-color: #ffcf4f;
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 80px;
  height: 40px;
  text-align: center;
}

.start-btn {
  border-radius: 20px;
  border: none;
  padding: 10px 20px;
  background: #ffcf4f;
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: medium;
  box-shadow: 0px 2px 5px rgba(233, 136, 136, 0.2);
  width: auto;
  text-align: center;
}

.chat-history-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.5rem 20px 1.5rem;
}

.chat-history-container.user {
  justify-content: flex-start;
}

.message-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.message-wrapper.user {
  justify-content: flex-end;
}

.chat-bubble {
  border: 1px solid #ddd;
  border-radius: 18px;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  line-height: 24px;
  max-width: 80%;
  min-width: 80%;
  word-wrap: break-word;
  white-space: pre-wrap;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.chat-bubble.user {
  background: #e5e6ff;
}

.chat-bubble.bot {
  background: #ffffff;
}

.icon {
  cursor: pointer;
}

.icon.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* End */

chatbot-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.progress-bar-container {
  display: flex;
  align-items: center;
  width: 87%;
  margin-bottom: 10px;
}

.session-title {
  margin-right: 10px;
  font-weight: bold;
}

.progress-bar {
  flex: 1;
  height: 18px;
  background-color: rgb(255, 207, 79);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.progress-bar-fill {
  height: 100%;
  background-color: #a0a8ff;
  transition: width 0.5s ease-in-out;
  border-radius: 10px;
}

.progress-percentage {
  margin-left: 10px;
  font-weight: bold;
  color: #000;
}

.chat-window {
  width: 100%;
  flex: 1;
  display: flex;
  overflow-y: auto;
  padding: 10px 20px;
  background-color: #ffffff;
}

.chat-history {
  flex: 1;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
}

.chat-entry {
  margin-bottom: 20px;
  display: flex;
}

.user-chat {
  justify-content: flex-end;
}

.bot-chat {
  justify-content: flex-start;
}

.chat-content {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.chat-bubble {
  border: 1px solid #ddd;
  border-radius: 18px;
  padding: 15px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 24px;
  max-width: 80%;
  word-wrap: break-word;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  white-space: pre-wrap;
}

.user-bubble {
  background: #e5e6ff;
}

.bot-bubble {
  background: #ffffff;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: center;
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid #e3e3fb;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.option-button {
  border-radius: 20px;
  border: none;
  padding: 10px 20px;
  background: #ffcf4f;
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.option-button.numeric {
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
}

.text-input-container {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 10px;
  border-top: 1px solid #e3e3fb;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.user-input {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 16px;
  resize: none;
}

.submit-button {
  border-radius: 20px;
  border: none;
  background: #ffcf4f;
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 80px;
  height: 40px;
  text-align: center;
}

.submit-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.start-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: center;
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid #e3e3fb;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.start-button {
  border-radius: 20px;
  border: none;
  padding: 10px 20px;
  background: #ffcf4f;
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: medium;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  width: auto;
  text-align: center;
}
