/*home page styles  */
.homePage {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  width: 100%;
  align-items: center;
  padding: 10px 40px;
  overflow: hidden;
}
.home_container {
  width: 100%;
  max-width: 500px;
}
.homePage .home_BoxContainer {
  background-color: #e5e6ff;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  width: 100%;
  gap: 10px;
  max-width: 500px;
  height: auto;
  padding: 2rem;
  border: 3px solid #6869f7;
  border-radius: 20px;
  margin: 10px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.homePage .home_LearnButton {
  background-color: rgb(255, 207, 79);
  border-radius: 20px;
  margin: 10px 0px;
  padding: 8px 16px;
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
.homePage .homeMain_heading {
  font-size: 2rem;
  font-weight: bold;
}
.homePage .home_paragraph {
  width: 100%;
  max-width: 500px;
  font-size: 16px;
  margin: 10px auto;
}
.homePage .home_subHeading {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px auto;
}
.homePage .home_title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.homePage .home_italicText {
  font-size: 12px;
  margin-top: 15px;
  color: grey;
}
@media (max-width: 800px) {
  .homePage .home_paragraph {
    width: 100%;
  }
}
