.privacy_policy_and_tos .privacy_topIcon {
  display: flex;
  align-items: center;
}

.privacy_policy_and_tos .privacy_crossIcon {
  padding-right: 50px;
}

.privacy_policy_and_tos .privacy_topContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.privacy_policy_and_tos .privacy_backIcon {
  justify-content: center;
}

.privacy_policy_and_tos .privacy_container {
  padding: 20px;
  background-color: #e5e6ff;
  width: 100%;
  box-sizing: border-box;
}

.privacy_policy_and_tos .privacy_iconLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  width: 100%;
}

.privacy_policy_and_tos .privacy_heading {
  padding: 10px;
  margin-bottom: 20px;
  border-bottom: 2.5px solid rgba(76, 77, 82, 0.34);
  font-size: 2rem;
  text-align: center;
}

.privacy_policy_and_tos .Privacy_textContainer {
  background-color: white;
  padding: 20px;
  font-size: 18px;
  border-radius: 20px;
}
.privacy_policy_and_tos .privacy_subHeading {
  padding: 10px 0;
  font-size: 20px;
}

/* @media (max-width: 600px) {
  .textContainer {
    padding: 15px;
    font-size: 16px;
  }
} */

/* @media (max-width: 600px) {
  .heading {
    font-size: 1.5rem;
  }
} */

/* @media (max-width: 600px) {
  .subHeading {
    font-size: 18px;
  }
} */
